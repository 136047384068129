.content {
  display: flex;
  gap: 20px;
  margin: 60px 0;
  align-items: center;
}
.text1 {
  margin-top: 25px;
  color: #000;
  font-size: 18px;
  line-height: 130%;
}
.text2 {
  margin-top: 25px;
  color: #3c3c3c;
  font-size: 18px;
  line-height: 130%;
}
.item:first-child {
  flex: 3;
}
.item:last-child {
  flex: 2;
}
.logo {
  margin-top: 50px;
}
@media screen and (max-width: 1024px) {
  .content {
    margin: 30px 0;
  }
}
@media screen and (max-width: 800px) {
  .content {
    flex-direction: column;
    margin: 15px 0;
  }
  .text {
    font-size: 14px;
  }
  .logo {
    margin-top: 0;
  }
}
