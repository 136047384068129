.root {
  background: #fbfbfb;
  padding: 40px 0;
}

.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 285px;
  display: block;
  margin-bottom: 30px;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.social {
  display: flex;
  margin-bottom: 10px;
  gap: 5px;
}
.social p {
  color: #8b8b8b;
}
.buttons {
  display: flex;
}
.mybtn {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #fff;
}
.user {
  width: 24px;
  height: 24px;
}
.mkey {
  display: flex;
  gap: 5px;
}
.mkey img {
  max-width: 41px;
}
.footer__bootom {
  padding: 20px 0;
}
.grey {
  color: #8b8b8b;
  font-size: 14px;
  transition: color 0.3s;
}
.policy {
  text-decoration-line: underline;
}
a.grey:hover {
  color: #111;
}
.buttons a button,
.buttons a {
  width: 100%;
}
.logo__wrapp {
  max-width: 30%;
}

@media screen and (max-width: 800px) {
  .inner {
    flex-direction: column;
    align-items: start;
    gap: 15px;
  }
  .logo {
    width: 180px;
  }
  .logo__wrapp {
    max-width: 100%;
  }
}
