.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 793px;
  .title {
    margin-bottom: 30px;
  }
  .subtitle {
    margin-bottom: 15px;
  }
  .text {
    color: #757575;
    margin-bottom: 30px;
    text-align: center;
    a {
      color: #757575;
      text-decoration: underline;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    button {
      width: 231px;
      margin: 30px 0;
    }
  }
}
.login {
  display: flex;
  justify-content: center;
  align-items: center;
}
