.root {
  border-radius: 20px;
  background: #fbfbfb;
  overflow: hidden;
  position: relative;
  padding: 25px;
  flex: 1;
  &:hover {
    .editButtons {
      opacity: 1;
    }
  }
}
.image {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.editButtons {
  position: absolute;
  right: 15px;
  top: 15px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  opacity: 0;
  transition: all 0.15s ease-in-out;
}
.price {
  color: #7400d1;
  font-size: 14px;
  text-align: center;
  margin: 15px 25px;
}
.price span {
  color: #7400d1;
  font-family: Unbounded;
  font-size: 20px;
}
.title {
  color: #000;
  font-family: Unbounded;
  font-size: 20px;
  text-align: center;
  border-radius: 7px;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 0;
  gap: 3px;
  border-radius: 7px;
}
.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}
.text ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.skeleton {
  flex: 1;
}
.skeletonContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  .title {
    font-size: 16px;
  }
  .text {
    font-size: 14px;
  }
}
@media screen and (max-width: 800px) {
  .root {
    padding: 15px;
    margin-top: 35px;
  }
}
@media screen and (max-width: 600px) {
  .root {
    margin-top: 0;
    padding: 10px;
  }
}
