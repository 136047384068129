.notfullpost {
  position: relative;
  &:hover {
    .editButtons {
      opacity: 1;
      z-index: 2;
    }
  }
}
.editButtons {
  position: absolute;
  left: 15px;
  top: 15px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  opacity: 0;
  transition: all 0.15s ease-in-out;
}
.notfullpost {
  min-height: auto;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  border-radius: 20px;
  background: #fbfbfb;
  padding: 10px;
}
.wrapp {
  display: flex;
  position: relative;
  gap: 15px;
}
.imageWrapp {
  width: 276px;
  height: auto;
  border-radius: 20px;
  background-color: #fff;
  overflow: hidden;
}
.imageWrapp img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.tags {
  list-style: none;
  display: flex;
  gap: 10px 20px;
  flex-wrap: wrap;
}
.title {
  color: #000;
  font-family: Unbounded;
  font-size: 18px;
  margin: 20px 0 15px;
}
.postDetails {
  position: absolute;
  right: 10px;
  top: 10px;
}
.postDetails {
  display: flex;
  align-items: center;
  gap: 5px;
}
.indention {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.link {
  position: absolute;
  right: 15px;
  bottom: 10px;
  font-weight: 600;
  text-decoration-line: underline;
}
.fullpost {
  display: flex;
}
.fullpost .wrapp {
  flex-direction: column;
  max-width: 70%;
}
.fullpost .imageWrapp {
  width: 100%;
  height: auto;
  border-radius: 20px;
  border: 1px solid #f2f2f2;
}
.fullpost .postDetails {
  display: none;
}
.tagsBlock {
  min-width: 25%;
  margin-right: 20px;
}
.text {
  margin-top: 20px;
  color: #3c3c3c;
  font-size: 18px;
  line-height: 140%; /* 25.2px */
}
.data {
  color: #9c9c9c;
}
.skeletonTags {
  display: flex;
  gap: 20px;
}
.skeletonWrapp {
  display: flex;
}
// .content {
//   width: max-content;
// }
@media screen and (max-width: 800px) {
  .wrapp {
    flex-direction: column;
    max-width: 100%;
    padding-bottom: 20px;
  }
  .fullpost .wrapp {
    max-width: 100%;
  }
  .imageWrapp {
    width: 100%;
    height: auto;
  }
  .postDetails {
    display: none;
  }
  .link {
    font-size: 14px;
    bottom: 5px;
    right: 5px;
  }
  .fullpost {
    flex-direction: column;
  }
  .tagsBlock {
    width: 100%;
  }
}
