.wrapp {
  display: flex;
  gap: 80px;
  margin-top: 40px;
}
.item {
  gap: 4px;
  display: flex;
}
.text {
  color: #8b8b8b;
  font-size: 18px;
}
.link {
  transition: color 0.3s;
  font-size: 18px;
}
.wrapp a:hover {
  color: #8b8b8b;
}
@media screen and (max-width: 1024px) {
  .wrapp {
    gap: 20px;
  }
}
@media screen and (max-width: 800px) {
  .wrapp {
    flex-direction: column;
    margin-top: -20px;
    gap: 10px;
  }
  .text,
  .link {
    font-size: 14px;
  }
}
