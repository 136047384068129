.wrapp {
  display: flex;
  padding: 50px;
  border-radius: 20px;
  background: #f6ecfe;
  gap: 100px;
}
.title {
  color: #181818;
  font-family: Unbounded;
  font-size: 26px;
  margin-bottom: 30px;
}
.b,
.text {
  color: #181818;
  font-size: 20px;
  line-height: 140%;
  max-width: 405px;
}
.inputs {
  display: flex;
  gap: 10px;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.item {
  width: 100%;
}
.submitButton {
  margin: 20px auto 0 !important;
  width: max-content;
}
.root {
  margin-bottom: 120px;
}
@media screen and (max-width: 1024px) {
  .wrapp {
    gap: 20px;
  }
}
@media screen and (max-width: 800px) {
  .wrapp {
    flex-direction: column;
    padding: 20px;
  }
  .b,
  .text {
    max-width: none;
    font-size: 14px;
  }
  .title {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .root {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 600px) {
  .inputs {
    flex-direction: column;
  }
  .submitButton {
    width: 60% !important;
    margin: 10px 0 !important;
  }
}
