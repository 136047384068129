.tabContent {
  background-color: #ebedec;
  padding: 10px;
  border-radius: 20px;
  overflow: auto;
}
.title {
  color: #181818;
  font-family: Unbounded;
  font-size: 26px;
  margin: 20px 0 10px 20px;
}
.select {
  margin: 10px 0 20px 10px;
}

@media screen and (max-width: 800px) {
  .tabWrap {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    gap: 10px;
  }
  .tabWrap a button,
  .tabWrap a {
    width: 100%;
    margin: 0;
  }
  tr {
    white-space: pre !important;
  }
}
