.root {
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 20px;
  background: #fbfbfb;
  padding: 20px;
  position: relative;
  .btn {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 15px;
    top: 15px;
    border-radius: 50%;
    background-color: #d3ff4e;
    span {
      background-color: #000;
      width: 50%;
      height: 1px;
      display: block;
      translate: -50% -50%;
      margin: 50%;
      &:first-child {
        rotate: 90deg;
        position: absolute;
        transition: 0.5s;
      }
    }
  }
  &.active {
    .text {
      margin-top: 10px;
      transition: 0.5s;
    }
    span {
      &:first-child {
        rotate: 0deg;
        position: absolute;
        transition: 0.5s;
      }
    }
  }
}
.text {
  color: #5a5a5a;
  font-size: 18px;
  line-height: 130% !important;
  transition: 0.5s;
}
@media screen and (max-width: 800px) {
  .text {
    font-size: 14px;
  }
}
