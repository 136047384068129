.button {
  padding: 10px 40px;
  font-size: 16px;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.9;
  }
}

.dark {
  background: #181818;
  color: white;
}

.grey {
  background: #f2f2f2;
  color: #181818;
}

.white {
  background: #fff;
  color: #181818;
}

.pink {
  background: #d2a0fa;
  color: white;
}

.darkGrey {
  background: #a7a7a7;
  color: white;
}

.text {
  font-weight: 600;
  background: transparent;
  text-decoration-line: underline;
  color: #181818;
}

.fullWidth {
  width: 100%;
}
@media screen and (max-width: 800px) {
  .button {
    padding: 10px 20px;
  }
}
