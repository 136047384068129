.root {
  width: 400px;
  padding: 50px;
  border: 1px solid #dedede;
  margin: 50px auto;
}

.field {
  margin-bottom: 20px !important;
}
.wrapp {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.titleWrap {
  display: flex;
  align-items: center;
  gap: 27px;
  margin-bottom: 35px;
}
.titleWrap .title {
  margin-bottom: 0;
}
@media screen and (max-width: 600px) {
  .wrapp {
    flex-direction: column;
    gap: 15px;
  }
  .titleWrap {
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 10px;
    flex-direction: column;
  }
}
