.root {
  border-radius: 20px;
  background: #fbfbfb;
  padding: 10px;
}
.root.none {
  display: none;
}
.list {
  list-style: none;
  margin: 20px 0 40px;
}
.title {
  border-radius: 10px;
  background: #f6ecfe;
  padding: 10px;
  font-size: 18px;
  font-weight: 600;
}
.item {
  border-radius: 10px;
  width: 100%;
  color: #000;
  transition: background 0.4s;
  &:hover {
    background: #fff;
  }
}
.item a {
  width: 100%;
  padding: 10px;
  display: block;
}
.mobile {
  display: none;
}
@media screen and (max-width: 1024px) {
  .btn {
    display: none !important;
  }
  .list {
    margin: 10px 0;
  }
}
