.image {
  max-width: 131px;
  background-color: #fff;
}
.table {
  background: #fbfbfb !important;
  border-radius: 20px !important;
}
.table td,
.table th {
  border: none !important;
  background: transparent;
}
.greenBg {
  width: fit-content;
  margin: 20px 0 35px;
}
.policy {
  margin: 30px 0 35px;
  max-width: 691px;
  font-size: 14px;
}
.price {
  font-weight: 600;
}
@media screen and (max-width: 500px) {
  .greenBg {
    margin-bottom: 15px;
  }
}
