.root {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.item:first-child {
  border-radius: 15px;
  background: #f6ecfe;
  padding: 20px;
}
.title {
  font-size: 20px;
  font-weight: 500;
  display: flex;
  gap: 5px;
  align-items: center;
}
.text {
  margin-bottom: 15px;
  display: block;
  max-width: 80%;
}
.text:first-child {
  font-size: 18px;
  font-weight: 500;
}
@media screen and (max-width: 800px) {
  .text {
    margin-bottom: 14px;
    max-width: 100%;
    font-size: 14px;
  }
  .title {
    font-size: 16px;
  }
  .item:first-child {
    padding: 10px;
  }
}
