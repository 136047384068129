.thx {
  color: #181818;
  font-family: Unbounded;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 60px;
}
.title {
  font-size: 20px;
  font-weight: 500;
}
.squ {
  color: #787878;
}
.image {
  width: 360px;
  margin: 20px 0 50px;
}
