.wrapp {
  display: flex;
  gap: 20px;
}
.item {
  flex: 1;
  max-width: 50%;
}
.title {
  color: #000;
  font-family: Unbounded;
  font-size: 24px;
}
.squ {
  margin-top: 5px;
  color: #787878;
}
.price {
  color: #787878;
  margin-top: 20px;
}
.price span {
  color: #181818;
  font-family: Unbounded;
  font-size: 24px;
  font-weight: 500;
}
.option ul {
  margin: 20px 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.description {
  color: #000;
  font-size: 18px;
  margin-top: 20px;
}
.btns {
  display: flex;
  gap: 16px;
  margin-top: 57px;
  width: 100%;
}
.btns button {
  width: 40%;
}
.item:first-child {
  background-color: #fbfbfb;
  border-radius: 20px;
  display: flex;
  align-items: center;
  position: relative;
}
.greenBg {
  position: absolute;
  top: 10px;
  left: 10px;
}
.buttonGroup {
  border-radius: 34px;
  background: #fbfbfb;
  max-width: max-content;
}
.btn {
  cursor: pointer;
  border: none;
  box-shadow: none;
  padding: 8px 30px;
  background: #fbfbfb;
  border-radius: 34px !important;
  &.active {
    background: #d3ff4e !important;
  }
}
.skeletonContent {
  display: flex;
  gap: 20px;
}
.skeletonItem {
  flex: 1;
}
.skeletonBtns {
  display: flex;
  width: 70%;
  gap: 20px;
}
.skeletonBtn {
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .btns {
    flex-direction: column;
  }
}
@media screen and (max-width: 800px) {
  .skeletonContent,
  .wrapp {
    flex-direction: column;
  }
  .item {
    max-width: 100%;
  }
  .image {
    margin: 40px 0;
  }
  .btns {
    flex-direction: row;
  }
  .title {
    font-size: 18px;
  }
  .description {
    font-size: 14px;
  }
  .btns {
    margin-top: 20px;
  }
  .btns a,
  .btns .customBtn {
    flex: 50%;
  }
  .btn {
    padding: 10px 5px;
    flex: 1;
  }
  .buttonGroup {
    max-width: 100%;
    display: flex;
  }
  .price span {
    font-size: 16px;
  }
}
