.root {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}
.tags {
  min-width: 25%;
}
.lists {
  width: 100%;
}
@media screen and (max-width: 800px) {
  .root {
    flex-direction: column;
  }
}
