.nav {
  list-style: none;
  display: flex;
  flex-direction: column;
  a {
    transition: color 0.3s;
    &:hover {
      color: #a7a7a7;
    }
  }
}

@media screen and (max-width: 800px) {
  .nav {
    a {
      font-size: 14px;
    }
  }
}
@media screen and (max-width: 1200px) {
  header .nav {
    gap: 30px !important;
  }
}
