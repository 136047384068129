body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  scroll-padding: 50px;
}
html {
  scroll-behavior: smooth;
  scroll-padding: 50px;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  margin: 0;
}
* {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  line-height: normal !important;
  margin: 0;
  padding: 0;
  color: #000;
  font-weight: 400;
  text-decoration: none;
}
main {
  min-height: calc(100vh - 294px);
  margin-top: 80px;
  position: relative;
  margin-bottom: 120px;
}
// main.isFullPost {
//   margin-top: 120px;
// }
img {
  width: 100%;
  height: auto;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
}
.title {
  color: #181818;
  font-family: "Unbounded", sans-serif;
  font-size: 26px;
  line-height: 140%;
  margin-bottom: 35px;
}
.subtitle {
  color: #000;
  font-family: "Unbounded", sans-serif;
  font-size: 18px;
  font-weight: 500;
}
.section {
  margin-top: 120px;
}
.greenBg {
  border-radius: 47px;
  background: #d3ff4e;
  padding: 5px 35px;
  color: #181818;
  font-size: 18px;
  font-style: italic;
  width: max-content;
}
.bred {
  position: absolute;
  top: 0;
  left: 0;
}
.pay__btn {
  border-radius: 7px;
  background: #d2a0fa;
  padding: 10px 18px;
  color: #fff;
  border: none;
  cursor: pointer;
}
#preloader {
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
}
.textBlock {
  line-height: 1.6 !important;
}
.textBlock h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 30px 0 10px;
}
header.hide {
  top: -100px;
}
@media screen and (max-width: 1200px) {
  .container {
    max-width: 1024px;
  }
}
@media screen and (max-width: 1024px) {
  .container {
    max-width: 800px;
  }
}
@media screen and (max-width: 800px) {
  .container {
    max-width: 100%;
    padding: 0 20px;
  }
  .subtitle {
    font-size: 14px;
    font-weight: 400;
  }
  .title {
    font-size: 18px;
  }
  main.isFullPost {
    margin-top: 70px;
  }
  button {
    font-size: 14px !important;
    // padding: 10px !important;
  }
  main {
    margin-bottom: 60px;
    min-height: 100vh;
  }
  .section {
    margin-top: 50px;
  }
  .greenBg {
    border-radius: 47px;
    padding: 5px 15px;
    font-size: 14px;
    width: auto;
  }
}
