.wrapp {
  display: flex;
  flex-wrap: wrap;
  row-gap: 40px;
}
.item {
  flex: 50%;
}
.text {
  max-width: 97%;
  color: #5a5a5a;
  font-size: 18px;
  line-height: 130%;
}
.number {
  color: #d2a0fa;
  font-family: Unbounded;
  font-size: 18px;
  padding: 6px;
  border-radius: 4px;
  background: #f6ecfe;
  display: block;
  max-width: max-content;
}
.title {
  color: #181818;
  font-family: Unbounded;
  font-size: 18px;
  margin: 15px 0 10px 0;
}

@media screen and (max-width: 800px) {
  .wrapp {
    gap: 15px;
  }
  .item {
    flex: 100%;
  }
  .text {
    font-size: 16px;
  }
  .title {
    font-size: 14px;
  }
}
