.root {
  display: flex;
  gap: 20px;
  padding-top: 40px;
}
.item {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content h1,
.content h1 span {
  color: #181818;
  font-family: Unbounded;
  font-size: 26px;
  line-height: 140%;
  margin-bottom: 20px;
}
.content h1 span {
  color: #7400d1;
}
.content p {
  color: #606060;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.content .btns {
  margin-top: 34px;
  display: flex;
  gap: 20px;
  button {
    font-size: 18px;
  }
}
@media screen and (max-width: 1440px) {
  .root {
    padding-top: 0;
  }
  .item {
    justify-content: start;
    gap: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .content .btns {
    flex-direction: column;
  }
  .content h1,
  .content h1 span {
    font-size: 20px;
  }
}
@media screen and (max-width: 800px) {
  .item:last-child {
    display: none;
  }
  .item {
    gap: 20px;
  }
  .content .btns {
    flex-direction: row;
    width: 100%;
  }
  .content .btns .customButton,
  .content .btns a {
    flex: 1;
    width: 100%;
  }
  .btns button {
    padding: 10px;
  }
}
