.wrapp {
  display: flex;
  gap: 20px;
}
.item {
  flex: 1;
  position: relative;
  border-radius: 20px;
  max-width: 50%;
}
.title {
  font-family: Unbounded;
  font-size: 48px;
}
.subtitle {
  font-family: Unbounded;
  font-size: 18px;
}
.text {
  color: #3c3c3c;
  font-size: 18px;
  margin-top: 15px;
}
.item:first-child {
  background: #d3ff4e;
  padding: 40px;
}
.item:last-child {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.top {
  flex: 1;
  display: flex;
  gap: 20px;
}
.bottom {
  flex: 1;
  border-radius: 20px;
  background: #fbfbfb;
  padding: 40px 20px 20px 20px;
  position: relative;
}
.small {
  flex: 1;
  border-radius: 20px;
  background: #fbfbfb;
  padding: 40px 20px;
}
.small:last-child {
  background: #d2a0fa;
}
.image {
  position: absolute;
  bottom: 0;
  right: 44px;
  width: auto;
}
.item:first-child .text {
  max-width: 60%;
}
.logo {
  margin-top: 20px;
}
@media screen and (max-width: 1024px) {
  .wrapp {
    flex-direction: column;
  }
  .image {
    position: relative;
    align-self: center;
  }
  .item {
    max-width: none;
  }
  .item:first-child {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }
  .text {
    max-width: 100% !important;
  }
}
@media screen and (max-width: 600px) {
  .image {
    width: 70%;
  }
  .title,
  .subtitle {
    font-size: 16px;
  }
  .text {
    font-size: 14px;
  }
  .top {
    flex-direction: column;
  }
  .top,
  .bottom,
  .small {
    gap: 15px;
  }
  .item:first-child {
    padding: 15px 15px 0 15px;
  }
  .bottom,
  .small {
    padding: 15px;
  }
}
