.root {
  //   background-color: rgba(255, 255, 255, 0.8);
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: all 0.4s;
}
.root.hidden {
  z-index: -100;
  scale: 0;
  opacity: 0;
}
.modal {
  border-radius: 20px;
  background: #f6ecfe;
  padding: 20px;
  min-width: 500px;
  position: relative;
}
.close {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 26px;
  line-height: 0.5 !important;
  cursor: pointer;
  padding: 10px;
}
.btns {
  display: flex;
  gap: 20px;
  justify-content: end;
  margin-top: 25px;
}
.title {
  color: #181818;
  font-family: Unbounded;
  font-size: 18px;
  margin-bottom: 10px;
}
