.root {
  border-radius: 20px;
  background: #fbfbfb;
  padding: 10px;
}
.link {
  display: block;
  padding: 8px;
  border-radius: 10px;
  transition: all 0.4s;
  &:hover {
    background: #f2f2f2;
  }
  &.active {
    background: #d2a0fa;
  }
}
.btn {
  width: 100%;
  border-radius: 10px;
  background: #d3ff4e;
  font-size: 18px;
  font-weight: 600;

  line-height: 130%;
  padding: 4px 4px 4px 8px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.img {
  width: 32px;
  height: 32px;
}
