.wrapp {
  display: flex;
  gap: 20px;
}
.item:first-child {
  flex: 1;
}
.item:last-child {
  flex: 3;
}
.title {
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 15px;
  background: #f6ecfe;
  padding: 20px;
  margin-bottom: 20px;
}
.table {
  border-radius: 20px;
  background: #fbfbfb;
}
.icon {
  width: 28px;
  height: 28px;
}
.text {
  font-weight: 600;
  margin-bottom: 10px;
}
.field {
  margin-bottom: 10px !important;
}
@media screen and (max-width: 1024px) {
  .item:last-child {
    flex: 1;
    max-width: 100%;
  }
  .title {
    padding: 10px;
    font-size: 16px;
  }
  .navbar {
    display: none;
  }
}
