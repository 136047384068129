.title {
  input {
    font-size: 22px;
    font-weight: 900;
  }

  div {
    &:before,
    &:after {
      display: none;
    }
  }
}

.image {
  width: 100%;
}

.tags {
  // margin: 15px !important;
  width: 45%;
}

.editor {
  margin: 30px 0;

  :global {
    .cm-s-easymde {
      border: 0;
      font-size: 22px;
    }
    .editor-toolbar {
      border: 0;
      background-color: rgb(0 0 0 / 2%);
    }
  }
}

.buttons {
  display: flex;

  button {
    margin-right: 15px;
  }
}
