.root {
  border-radius: 10px;
  background: #fbfbfb;
  padding: 20px;
  width: max-content;
  margin-bottom: 20px;
}
.title {
  font-weight: 700;
  margin-bottom: 10px;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.item {
  display: flex;
  align-items: center;
  gap: 6px;
}
.number {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d2a0fa;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.link {
  color: #2a26dc;
  text-decoration-line: underline;
}
.text {
  display: flex;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .root {
    width: 100%;
  }
}
